
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import NewCardModal from "@/components/modals/forms/NewCardModal.vue";
import Vouchers from "@/components/store-promotions/cards/Vouchers.vue";
import {
  IStorePromotion,
  StorePromotion,
  // getVouchers,
} from "@/core/data/Vouchers";

export default defineComponent({
  name: "store-promotion-vouchers",
  components: {
    Vouchers,
    // Dropdown3,
    NewCardModal,
  },
  setup() {
    const promotionData = ref<IStorePromotion>(StorePromotion);
    const promotionId = ref();
    const state = ref();
    const route = useRoute();
    const handleClickTabItem = (value) => {
      state.value = value;
    };

    onMounted(() => {
      // getVouchers(6)
      promotionId.value = route.params.promotionId;
      setCurrentPageBreadcrumbs("Chi tiết khuyến mãi", [
        "Apps",
        "Chương trình marketing",
      ]);
    });

    return {
      promotionData,
      promotionId,
      state,
      handleClickTabItem,
    };
  },
});
