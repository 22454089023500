import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-xl-row" }
const _hoisted_2 = { class: "flex-lg-row-fluid ms-lg-15" }
const _hoisted_3 = { class: "nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-1" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = {
  class: "tab-content",
  id: "myTabContent"
}
const _hoisted_8 = {
  class: "tab-pane fade show state active",
  id: "kt_customer_view_all_vouchers",
  role: "tabpanel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vouchers = _resolveComponent("Vouchers", true)!
  const _component_NewCardModal = _resolveComponent("NewCardModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("ul", _hoisted_3, [
          _createElementVNode("li", _hoisted_4, [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClickTabItem('ALL'))),
              class: "nav-link text-state-primary pb-4 state",
              "data-bs-toggle": "tab",
              href: "#"
            }, "Tất cả")
          ]),
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("a", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClickTabItem('NOT_USED_YET'))),
              class: "nav-link text-state-primary pb-4",
              "data-bs-toggle": "tab",
              href: "#kt_customer_view_unused_vouchers"
            }, "Chưa sử dụng")
          ]),
          _createElementVNode("li", _hoisted_6, [
            _createElementVNode("a", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClickTabItem('USED'))),
              class: "nav-link text-state-primary pb-4",
              "data-kt-countup-tabs": "true",
              "data-bs-toggle": "tab",
              href: "#kt_customer_view_used_vouchers"
            }, "Đã sử dụng")
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Vouchers, {
              "card-classes": "",
              state: _ctx.state,
              promotion: "promotionData",
              promotionId: _ctx.promotionId
            }, null, 8, ["state", "promotionId"])
          ])
        ])
      ])
    ]),
    _createVNode(_component_NewCardModal)
  ], 64))
}