
import {defineComponent, onMounted, ref, watch} from "vue";
import {getVouchers} from "@/core/data/Vouchers"
import {getPromotions} from "@/core/data/StorePromotion";
import moment from 'moment'
export default defineComponent({
  name: "events",
  props: {
    cardClasses: String,
    promotion:Object,
    promotionId: String,
    state: String
  },
  components: {},
  setup(props) {
    const vouchers = ref([])
    const metadata = ref([])
    onMounted( () => {
      // console.log(props)

    })
    watch(() => props.promotionId, (selection, prevSelection) => {
      getVouchers(props.promotionId)
          .then(response => {
            vouchers.value = response.data.data;
            metadata.value = response.data.metadata
          })
          .catch((e) => {
            console.log(e)
          })
    });
    watch(() => props.state, (selection, prevSelection) => {
      getVouchers(props.promotionId, props.state)
          .then(response => {
            vouchers.value = response.data.data;
            metadata.value = response.data.metadata
          })
          .catch((e) => {
            console.log(e)
          })
    });
    return {
      vouchers,
      metadata
    };
  },
  methods: {
    getCountdownDateTime(endDate){
      let now = moment();
      endDate = moment.unix(endDate/1000);
      let diff = endDate.diff(now)
      let duration = moment.duration(diff)
      let days = Math.floor(duration.asDays())
      let hours = duration.hours()
      let minutes = duration.minutes()

      if(diff > 0){
        return days + ' ngày: ' + hours + ' giờ: ' + minutes + ' phút'
      }else{
        return 0 + ' ngày: ' + 0 + ' giờ: ' + 0 + ' phút'
      }
    }
    // getColorByState(state){
    //   if(state == 'NOT_USED_YET') return '#FD8C08'
    //   else return '#929498'
    // }
  }
});
