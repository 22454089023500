import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

interface IStorePromotion {
  id: number;
  code: string;
  name: string;
  packageId: number;
  countVoucher: number;
  discount: number;
  startDate: string;
  endDate: string;
  state: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

const StorePromotions: Array<IStorePromotion> = [
  {
    id: 1,
    code: "Top100VN",
    name: "Chương trình Top100",
    packageId: 1,
    countVoucher: 1,
    discount: 10,
    startDate: "2022-10-14 12:00:00",
    endDate: "2022-10-14 12:00:00",
    state: "ACTIVE",
    created_at: "2022-10-14 12:00:00",
    updated_at: "2022-10-14 12:00:00",
    deleted_at: "2022-10-14 12:00:00",
  },
];

const StorePromotion: IStorePromotion = {
  id: 1,
  code: "Top100VN",
  name: "Chương trình Top100",
  packageId: 1,
  countVoucher: 1,
  discount: 10,
  startDate: "2022-10-14 12:00:00",
  endDate: "2022-10-14 12:00:00",
  state: "ACTIVE",
  created_at: "2022-10-14 12:00:00",
  updated_at: "2022-10-14 12:00:00",
  deleted_at: "2022-10-14 12:00:00",
};
function getVouchers(promotion_id, state = "ALL") {
  const params = {
    'page': 0,
    'size': 9999,
  };
  if (state != "ALL") {
    params['state'] = state;
  }
  ApiService.setHeader();
  return ApiService.query(`/v1/package/promotion/${promotion_id}/voucher`, {
    params: params,
  });
}

function getVoucher(code) {
  const params = {
    code: code,
  };
  ApiService.setHeader();
  return ApiService.query("/v1/package/promotion/voucher", { params: params });
}

export { IStorePromotion, StorePromotion, getVoucher, getVouchers };

export default StorePromotions;
