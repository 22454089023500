import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d9649b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-body py-0" }
const _hoisted_2 = {
  style: {"margin-left":"5px"},
  class: "badge badge-success fs-5"
}
const _hoisted_3 = {
  class: "row mt-5 mb-5 scroll-y",
  style: {"height":"60vh"}
}
const _hoisted_4 = {
  style: {"border-radius":"10px"},
  class: "row border border-hover-dark cursor-pointer"
}
const _hoisted_5 = { class: "col-11" }
const _hoisted_6 = { class: "d-flex align-items-center bg-white rounded p-5 mb-7" }
const _hoisted_7 = { class: "flex-grow-1 me-2" }
const _hoisted_8 = { class: "fw-bold text-gray-600 d-block fs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card pt-4 ${_ctx.cardClasses}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, [
        _createTextVNode(_toDisplayString(_ctx.promotion.name) + " ", 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.metadata.total) + " mã ", 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vouchers, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-4 py-3 pe-10",
            key: index
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                style: _normalizeStyle([item.state == 'NOT_USED_YET' ? {'background-color':  '#FD8C08'} : {'background-color':  '#929498'}]),
                class: "voucher-label col-1 border-left-1"
              }, null, 4),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", {
                      style: _normalizeStyle([item.state == 'NOT_USED_YET' ? {'color':  '#FD8C08'} : {'color':  '#929498'}]),
                      class: "fw-bolder text-hover-primary fs-6"
                    }, " Giảm " + _toDisplayString(item.packagePromotion.discountRate) + "% giá trị đơn hàng ", 5),
                    _createElementVNode("span", _hoisted_8, "Thời gian còn lại: " + _toDisplayString(_ctx.getCountdownDateTime(item.packagePromotion.endDate)) + " ", 1),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", {
                        style: _normalizeStyle([item.state == 'NOT_USED_YET' ? {'color':  '#FD8C08'} : {'color':  '#929498'}]),
                        class: "fs-3 mt-3 bg-light-warning fw-bold d-block"
                      }, _toDisplayString(item.code), 5)
                    ])
                  ])
                ])
              ])
            ])
          ]))
        }), 128))
      ])
    ])
  ], 2))
}